import React, { useState , useEffect } from 'react';
import {Link , useNavigate } from 'react-router-dom';
import { useSelector , useDispatch } from 'react-redux';
import { resetCart } from '../Store/cartSlice';
import axios from "axios";
import Loader from "./Loader";

function Checkout(){
  

  const navigate = useNavigate();
  const cartProduct = useSelector(state => state.cart);
  const {currency , currency_name} = useSelector((state) => state.internal);
  const [errors, setErrors] = useState({});
  const [shippingMethod, setShippingMethod] = useState('1');
  const [paymentMethod, setPaymentMethod] = useState('Online');
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentcur, setCurrentcur] = useState('');
  const [orderid, setOrderid] = useState('');
  const [loading, setLoading] = useState(false);
  
  var user_id = localStorage.getItem('user_id');
  
  const dispatch = useDispatch();
  useEffect(()=>{
    if(currentcur !== currency && currentcur !== ''){
        dispatch(resetCart());
        navigate('/');
    }else{
        setCurrentcur(currency);
    }
},[currency , currency_name]) 

  const handleShippingChange = (e) => {
    setShippingMethod(e.target.value);
  };

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
  };

    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        town: '',
        country: '',
        phone: '',
        email: '',
        notes: ''
      });
    
      const handleChange = (e) => {
        const { id, value } = e.target;
        setFormState(prevState => ({
          ...prevState,
          [id]: value
        }));
      };

      const validate = () => {
        let errors = {};
        if (!formState.firstName) errors.firstName = 'First Name is required';
        if (!formState.lastName) errors.lastName = 'Last Name is required';
        if (!formState.address1) errors.address1 = 'Address is required';
        if (!formState.town) errors.town = 'Town is required';
        if (!formState.phone) errors.phone = 'Phone is required';
        if (!formState.email) errors.email = 'Email is required';
    
        setErrors(errors);
        return Object.keys(errors).length === 0;
      };

      function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

      async function displayRazorpay(totalAmount , formState , currency_name) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const orderdata = new FormData();
        orderdata.append('currency' , currency_name);
        orderdata.append('totalAmount' , totalAmount);

        axios.post('https://drfrnd.com/api.php?type=rez_orderid' , orderdata)
            .then((response)=>{
                setOrderid(response.data);
        })

        
        const checkdata = new FormData();
        checkdata.append('orderCreationId' , orderid);

        const options = {
            key: "rzp_live_En7C5KhJuc7TBl", 
            amount: totalAmount*100, // Amount in paise
            currency: currency_name,
            name: formState.firstName,
            description: "Test Transaction",
            order_id: orderid, 
            handler: async function (response) {
                
              setLoading(true); 
              console.log(response);
              checkdata.append('razorpayPaymentId' , response.razorpay_payment_id);
              checkdata.append('razorpayOrderId' , response.razorpay_order_id);
              checkdata.append('razorpaySignature' , response.razorpay_signature);
              try {
                
                axios.post('https://drfrnd.com/api.php?type=checkpayment' , checkdata)
                    .then((response)=>{
                    if(response.data.status){
                        send_to_order(response.data.razorpayOrderId);
                    }else{
                        alert(response.data.message);
                    }
                })
                
              } catch (error) {
                console.error('Payment success API call failed', error);
                alert('Payment verification failed. Please try again.');
              } finally {
                setLoading(false); // Stop loading
              }
            },
            prefill: {
              name: formState.firstName,
              email: formState.email,
              contact: formState.phone,
            },
            notes: {
              address: "DRFRND",
            },
            theme: {
              color: "#61dafb",
            },
          };
      
        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            console.error(response.error);
            alert('Payment failed. Please try again.');
        });
        paymentObject.open();
    }

      function send_to_order(txnid){

        const formdata = new FormData();

            formdata.append('user_id' , user_id);
            formdata.append('txn_id' , txnid);
            formdata.append('payment_method' , paymentMethod);
            formdata.append('shipping_method' , shippingMethod);
            formdata.append('cart' , JSON.stringify(cartProduct));
            formdata.append('formdata' , JSON.stringify(formState));

            axios.post('https://drfrnd.com/api.php?type=checkout' , formdata)
            .then((response)=>{
                console.log(response);
                if(response.data.status === 1){
                    navigate(`/thankyou/${response.data.data.order_id}`);
                }else{
                    alert(response.data.message);
                }
            })
      }

      function submitcheckout(){
        if (validate()) {
            
            if(shippingMethod === 'COD'){
                send_to_order('XXXXXXXXXXXX');
            }else{
                displayRazorpay(totalAmount , formState , currency_name);
            }
        }
      }

      useEffect(() => {
        const total = cartProduct.reduce((sum, item) => parseFloat(sum) + parseFloat(item.pro_price)*item.pro_qty , 0);
        setTotalAmount(total);
      }, [cartProduct]);


  return(
    <>
    {loading && <Loader />}

        <div className="axil-breadcrumb-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8">
                        <div className="inner">
                            <ul className="axil-breadcrumb">
                                <li className="axil-breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="separator"></li>
                                <li className="axil-breadcrumb-item active" aria-current="page">Checkout</li>
                            </ul>
                            <h1 className="title">Checkout</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4">
                        <div className="inner">
                            <div className="bradcrumb-thumb">
                                <img src="/assets/images/product/product-45.png" alt="product"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

     <div className="axil-checkout-area axil-section-gap">
            <div className="container">
                <form action="#">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="axil-checkout-billing">
                            <div className="axil-checkout-billing">
                                <h4 className="title mb--40">Billing details</h4>
                                <div className="row">
                                    <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>First Name <span>*</span></label>
                                        <input
                                        type="text"
                                        id="firstName"
                                        placeholder="Adam"
                                        value={formState.firstName}
                                        onChange={handleChange}
                                        />
                                        {errors.firstName && <div className="error">{errors.firstName}</div>}
                                    </div>
                                    

                                    </div>
                                    <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Last Name <span>*</span></label>
                                        <input
                                        type="text"
                                        id="lastName"
                                        placeholder="John"
                                        value={formState.lastName}
                                        onChange={handleChange}
                                        />
                                        {errors.lastName && <div className="error">{errors.lastName}</div>}
                                    </div>
                                    
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Phone <span>*</span></label>
                                    <input
                                    type="tel"
                                    id="phone"
                                    value={formState.phone}
                                    onChange={handleChange}
                                    />
                                    {errors.phone && <div className="error">{errors.phone}</div>}
                                </div>
                                
                                <div className="form-group">
                                    <label>Email Address <span>*</span></label>
                                    <input
                                    type="email"
                                    id="email"
                                    value={formState.email}
                                    onChange={handleChange}
                                    />
                                    {errors.email && <div className="error">{errors.email}</div>}
                                </div>
                                
                                
                                
                                <div className="form-group">
                                    <label>Street Address <span>*</span></label>
                                    <input
                                    type="text"
                                    id="address1"
                                    className="mb--15"
                                    placeholder="House number and street name"
                                    value={formState.address1}
                                    onChange={handleChange}
                                    />
                                    {errors.address1 && <div className="error">{errors.address1}</div>}
                                    <input
                                    type="text"
                                    id="address2"
                                    placeholder="Apartment, suite, unit, etc. (optional)"
                                    value={formState.address2}
                                    onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Town/ City <span>*</span></label>
                                    <input
                                    type="text"
                                    id="town"
                                    value={formState.town}
                                    onChange={handleChange}
                                    />
                                    {errors.town && <div className="error">{errors.town}</div>}
                                </div>
                                <div className="form-group">
                                    <label>Country</label>
                                    <input
                                    type="text"
                                    id="country"
                                    value={formState.country}
                                    onChange={handleChange}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Other Notes (optional)</label>
                                    <textarea
                                    id="notes"
                                    rows="2"
                                    placeholder="Notes about your order, e.g. special notes for delivery."
                                    value={formState.notes}
                                    onChange={handleChange}
                                    ></textarea>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="axil-order-summery order-checkout-summery">
                            <h5 className="title mb--20">Your Order</h5>
                            <div className="summery-table-wrap">
                                <table className="table summery-table">
                                <thead>
                                    <tr>
                                    <th>Product</th>
                                    <th>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cartProduct.length > 0 && (
                                            cartProduct.map((item , kyes)=>
                                                <tr className="order-product" key={kyes}>
                                                <td>{item.pro_name} <span className="quantity">x {item.pro_qty}</span></td>
                                                <td>{item.pro_currency} {item.pro_price}</td>
                                                </tr>
                                            )
                                        )
                                    }

                                    <tr className="order-shipping">
                                    <td colSpan="2">
                                        <div className="shipping-amount">
                                        <span className="title">Shipping Method</span>
                                        <span className="amount">{currency_name} O.00</span>
                                        </div>
                                        <div className="input-group">
                                        <input 
                                            type="radio" 
                                            id="radio1" 
                                            value="1"
                                            checked={shippingMethod === '1'} 
                                            onChange={handleShippingChange} 
                                        />
                                        <label htmlFor="radio1">Free Shipping</label>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr className="order-total">
                                    <td>Total</td>
                                    <td className="order-total-amount">{currency_name} {totalAmount}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <div className="order-payment-method">

                                {/* <div className="single-payment">
                                <div className="input-group">
                                    <input 
                                    type="radio" 
                                    id="radio5" 
                                    value="COD"
                                    checked={paymentMethod === 'COD'} 
                                    onChange={handlePaymentChange} 
                                    />
                                    <label htmlFor="radio5">Cash on delivery</label>
                                </div>
                                <p>Pay with cash upon delivery.</p>
                                </div> */}
                                <div className="single-payment">
                                <div className="input-group justify-content-between align-items-center">
                                    <input 
                                    type="radio" 
                                    id="radio6" 
                                    value="Online"
                                    checked={paymentMethod === 'Online'} 
                                    onChange={handlePaymentChange} 
                                    />
                                    <label htmlFor="radio6">Razorpay</label>
                                    <img src="./assets/images/others/payment.png" alt="Paypal payment" />
                                </div>
                                <p>Pay via UPI, NetBanking, Debit Card & Credit Card</p>
                                </div>
                            </div>
                            <button type="button" onClick={() => submitcheckout()}className="axil-btn btn-bg-primary checkout-btn">Process to Checkout</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}

export default Checkout;
