import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Action
export const fetchComments = createAsyncThunk('fetchComments', async () => {
    try {
      const response = await axios.get("https://drfrnd.com/api.php?type=commendetails");
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = response.data;

      if (!data) {
        throw new Error('No data received');
      }

      return data;
    } catch (error) {
      console.error('Error fetching comments:', error); // Log any errors
      throw error;
    }
});

const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchComments.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default commentsSlice.reducer;
