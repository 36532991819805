import React, { useEffect ,useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import axios from 'axios';

function Slider(){

    const [sliderImages, setUsername] = useState([]);
    const [sliderstatus, Setsliderstatus] = useState(true);

    useEffect(()=>{
        getslider();
    },[]) 

    async function getslider (){
        const response = await axios.get("https://drfrnd.com/api.php?type=slider");
        setUsername(response.data);
        Setsliderstatus(false);
        console.log(response.data);
    }

    if(sliderstatus){
        return (
            <>
              <h1>Loading.........</h1>
            </>
          );
    }

    return(
        <>
       <div className="axil-main-slider-area main-slider-style-2">
            <div className="container-fluid">
                <div className="slider-offset-left">
                    <div className="row row--20">
                        
                        <SimpleImageSlider
                            width={"100%"}
                            height={560}
                            images={sliderImages}
                            showNavs={true}
                            autoPlay={true}
                            autoPlayDelay = {3}
                        />
                       
                    </div>
                </div>
            </div>
        </div>
        </>
    )  
}

export default Slider;
