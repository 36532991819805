import { createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";

const cartSlice = createSlice({
  name: 'cart',
  initialState: [],
  reducers: {
    add(state, action) {

      const item = state.find(
        (item) => item.pro_id === action.payload.pro_id
      );
      if (item) {
        item.pro_qty += action.payload.pro_qty;
      } else {
        state.push(action.payload);
      }
      toast.success("Product added to cart");
    },

    remove(state , action){
      return state.filter(item => item.pro_id !== action.payload);
    },

    increaseQuantity: (state, action) => {
      const item = state.find(
        (item) => item.pro_id === action.payload._id
      );
      if (item) {
        item.pro_qty++;
        // Dispatch a success toast
      }
    },
    drecreaseQuantity: (state, action) => {
      const item = state.find(
        (item) => item.pro_id === action.payload._id
      );
      if (item.pro_qty === 1) {
        item.pro_qty = 1;
      } else {
        item.pro_qty--;
        // Dispatch a success toast
      }
    },

    resetCart: (state) => {
      return [];
      // Dispatch a success toast
    }
  },
});


const { actions } = cartSlice; // Destructure the actions property
export const { add , remove , resetCart , drecreaseQuantity , increaseQuantity } = actions; 
export default cartSlice.reducer;

