import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch , useSelector } from 'react-redux';
import { add } from '../Store/cartSlice';

function Singleproduct() {

    const dispatch = useDispatch();
    const params = useParams();
    const product_id = params.id;

    const {currency,currency_name} = useSelector((state) => state.internal);

    const [singleproduct, setSingleProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [unit, setUnit] = useState('');
    const [purchrate, setPurchrate] = useState('');
    const [sellrate, setSellrate] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [error, setError] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [galimage, setgalimage] = useState('');

    const handleIncrement = () => {
        setQuantity((prevQuantity) => prevQuantity + 1);
    };

    const handleDecrement = () => {
        setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    };

    const handleChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setQuantity(isNaN(value) ? 1 : value);
    };
    
    useEffect(() => {
        getproduct();
    }, [product_id , currency , currency_name]);

    async function getproduct() {
        try {
            const result = await axios.get(`https://drfrnd.com/api.php?type=singleproduct&pro_id=${product_id}&cur_id=${currency}`);
            console.log(result.data.data);
            setSingleProduct(result.data.data);
            Setunit('' , '' , '' , '');
        } catch (error) {
            console.error(error);
            setError('Failed to fetch product');
        } finally {
            setLoading(false);
        }
    }

    function Setunit(item , purch_rate, sell_rate, discountPercentage){
        setUnit(item);
        setPurchrate(purch_rate);
        setSellrate(sell_rate);
        setDiscountPercentage(discountPercentage);
    }

    const addToCart =(pro_id, pro_name, pro_img , pro_currency) => {
        var product = {
            "pro_name" : pro_name,
            "pro_id" : pro_id,
            "pro_img" : pro_img,
            "pro_brand_name" : "Test",
            "pro_price" : purchrate,
            "pro_main_price" : sellrate,
            "pro_discount" : discountPercentage,
            "pro_unit" : unit,
            "pro_qty" : quantity,
            "pro_currency" : pro_currency,
            "pro_currency_id" : currency,
        }
        if(purchrate === '' && sellrate === '' && unit === ''){
            alert('Please Select Unit !');
        }else{
            dispatch(add(product));
        }
        
    }


    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <main className="main-wrapper">
                <div className="axil-breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="inner">
                                <ul className="axil-breadcrumb">
                                    <li className="axil-breadcrumb-item"><a href="index.html">Home</a></li>
                                    <li className="separator"></li>
                                    <li className="axil-breadcrumb-item active" aria-current="page">Single Product</li>
                                </ul>
                                <h1 className="title">{singleproduct[0]?.pro_name}</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <div className="inner">
                                <div className="bradcrumb-thumb">
                                    <img src="/assets/images/product/product-45.png" alt="product"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="axil-single-product-area axil-section-gap pb--0 bg-color-white">
                    <div className="single-product-thumb mb--40">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7 mb--40">
                                    <div className="row">
                                        <div className="col-lg-10 order-lg-2">
                                            <div className="single-product-thumbnail-wrap zoom-gallery">
                                                <div className="single-product-thumbnail product-large-thumbnail-3 axil-product">
                                                    <div className="thumbnail">
                                                        <Link to={singleproduct[0]?.pro_img} className="popup-zoom">
                                                            <img src={galimage?(galimage):(singleproduct[0]?.pro_img)} alt={singleproduct[0]?.pro_name} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="label-block">
                                                    <div className="product-badget">{
                                                    discountPercentage?(
                                                        discountPercentage
                                                    ):(
                                                        singleproduct[0]?.pro_discount
                                                    )
                                                    }% OFF</div>
                                                </div>
                                                {/* <div className="product-quick-view position-view">
                                                    <a href="/assets/images/product/product-big-01.png" className="popup-zoom">
                                                        <i className="far fa-search-plus"></i>
                                                    </a>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-2 order-lg-1">
                                            <div className="product-small-thumb-3 small-thumb-wrapper">

                                                <div className="small-thumb-img" onClick={()=>setgalimage(singleproduct[0]?.pro_img)}>
                                                    <img src={singleproduct[0]?.pro_img} alt="logo" />
                                                </div>

                                                {
                                                singleproduct[0]?.image_gallery.map((item , kyes)=>
                                                    <div className="small-thumb-img" onClick={()=>setgalimage(item)}>
                                                        <img src={item} alt="logo" />
                                                    </div>
                                                )
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 mb--40">
                                    <div className="single-product-content">
                                        <div className="inner">
                                            <h2 className="product-title">{singleproduct[0]?.pro_name}</h2>
                                            <span className="price-amount">
                                                { 
                                                    purchrate ? (
                                                         purchrate
                                                    ):(
                                                        singleproduct[0]?.pro_price
                                                    )
                                                }

                                                {singleproduct[0]?.pro_currency} 

                                                <del style={{marginLeft:'10px'}}>
                                                    
                                                { 
                                                    sellrate ? (
                                                        sellrate
                                                    ):(
                                                        singleproduct[0]?.pro_main_price
                                                    )
                                                }
                                               {singleproduct[0]?.pro_currency}</del>
                                            </span>


                                            <div className="product-rating">
                                                <div className="star-rating">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                </div>
                                            </div>
                                            <ul className="product-meta">
                                                <li><i className="fal fa-check"></i>In stock</li>
                                               
                                            </ul>

                                            <div className="product-variations-wrapper">
                                                <div className="product-variation product-size-variation">
                                                    <h6 className="title">Available In:</h6>
                                                    <ul className="range-variant">
                                                        {singleproduct[0]?.price_data.map((item, kyes) => (
                                                                <li
                                                                    key={kyes}
                                                                    onClick={() => Setunit(item.unit, item.purch_rate, item.sell_rate, item.discountPercentage )}
                                                                    className={unit === item.unit ? 'active' : ''}
                                                                >
                                                                    {item.unit}
                                                                </li>
                                                            ))}
                                                        
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="product-action-wrapper d-flex-center">
                                            <div className="pro-qty mr--20">
                                            <span className="dec qtybtn" onClick={handleDecrement}>-</span>
                                            <input type="text" value={quantity} onChange={handleChange} />
                                            <span className="inc qtybtn" onClick={handleIncrement}>+</span>
                                            </div>
                                                <ul className="product-action d-flex-center mb--0">
                                                    <li className="add-to-cart"><Link onClick={() => addToCart(singleproduct[0].pro_id,singleproduct[0].pro_name,singleproduct[0].pro_img , singleproduct[0]?.pro_currency)} className="axil-btn btn-bg-primary">Add to Cart</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="woocommerce-tabs wc-tabs-wrapper bg-vista-white" style={{ marginBottom: "30px", background:'#e0e9cf'}}>
                        <div className="container">
                            <ul className="nav tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                    <div className="product-desc-wrapper">
                                        <div className="row">
                                            <div className="col-lg-12 mb--30">
                                                <div className="single-desc">
                                                    <div dangerouslySetInnerHTML={{ __html: singleproduct[0]?.description }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Singleproduct;
