import React from "react";
import axios from "axios";

function Test(){

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // Fetch order_id from your backend (replace the URL with your backend endpoint)
        const { data: { order_id } } = await axios.get("https://drfrnd.com/api.php?type=rez_orderid", {
            amount: 1000, // Amount in paise
            currency: 'INR'
        });

        const options = {
            key: "rzp_test_5RJYr5U1tCUHwc", 
            amount: '1000', // Amount in paise
            currency: 'INR',
            name: "Soumya Corp.",
            description: "Test Transaction",
            order_id: order_id, 
            handler: async function (response) {
              const data = {
                orderCreationId: order_id, 
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
      
              try {
                const result = await axios.post("http://localhost:5000/payment/success", data);
                alert(result.data.msg);
              } catch (error) {
                console.error('Payment success API call failed', error);
                alert('Payment verification failed. Please try again.');
              }
            },
            prefill: {
              name: "Soumya Dey",
              email: "SoumyaDey@example.com",
              contact: "9999999999",
            },
            notes: {
              address: "Soumya Dey Corporate Office",
            },
            theme: {
              color: "#61dafb",
            },
          };
      
        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            console.error(response.error);
            alert('Payment failed. Please try again.');
        });
        paymentObject.open();
    }


    return(
        <>
        <h1>Test Page</h1>
            <button className="App-link" onClick={displayRazorpay}>
                Pay ₹500
            </button>
        </>
    )
}
export default Test;