import React from "react";
import Slider from "./Slider";
import Serve from "./Serve";
import Homeproduct from "./Homeproduct";
import Category from "./Category";


function Home(props){

    return(
        <>
        <main className="main-wrapper">
            <Slider/> 
            {/* <Serve/> */}
            <Category/>
            <Homeproduct/>
        </main>
        </> 
    )
    
}
export default Home;