import React from 'react';
import '../loder.css';
const Loader = () => {
    return (
        <>
            <div class="loading">Loading&#8230;</div> 
        </>
    );
  };
  
export default Loader;
  