import React , {useEffect} from "react";
import {useParams} from 'react-router-dom';
import {useDispatch } from 'react-redux';
import { resetCart } from "../Store/cartSlice";
function Thankyou(){

const params = useParams();
const order_id = params.id;

const dispatch = useDispatch();
useEffect(()=>{
    dispatch(resetCart())
},[]) 

  return(
    <>
    <main className="main-wrapper">
        <div className="axil-shop-area axil-section-gap bg-color-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    
                        <div className="axil-signin-form">
                            <h3 className="title">Thank you</h3>
                            <p style={{marginBottom:'5px'}}>Order is successfully placed, your order is on the way. Thanks for using DRFRND , Hope you are enjoying our service.</p>
                            <p className="">Order ID : #{order_id}</p>
                        </div>

                
                    </div>
                </div>
            </div>
        </div>
        </main>
    </>
  )
}

export default Thankyou;