import React , {useState} from "react";
import {useNavigate , Link} from 'react-router-dom';
import axios from "axios";

function Signup(){

    const navigate = useNavigate();

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [enterotp, setEnterotp] = useState("");

    const [showotp , setShowotp] = useState(false);
    const [msgid, setMsgid] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);


    const validate = () => {
        let errors = {};
        if (!firstname) errors.firstname = 'First Name is required';
        if (!lastname) errors.lastname = 'Last Name is required';
        if (!email) errors.email = 'Email is required';
        if (!phone) errors.phone = 'Phone is required';
        if (!password) errors.password = 'Password is required';

        setErrors(errors);
        return Object.keys(errors).length === 0;
      };

    function submitdata(){
        if(validate()){
            const formdata = new FormData() 
            formdata.append('fname' , firstname)
            formdata.append('lname' , lastname)
            formdata.append('email' , email)
            formdata.append('phone' , phone)
            formdata.append('password' , password)
            
            setLoading(true);

            axios.post('https://drfrnd.com/api.php?type=send_otp' , formdata)
            .then((response)=>{
                console.log(response);
                if(response.data.status === 1){
                    setLoading(false);
                    setShowotp(true);
                    setMsgid(response.data.data.mesg_id);
                }else{
                    setLoading(false);
                    alert(response.data.message);
                }
            })
        }
    }

    function submitotp(){

        if(validate()){

            const formdata = new FormData() 
            formdata.append('fname' , firstname)
            formdata.append('lname' , lastname)
            formdata.append('email' , email)
            formdata.append('phone' , phone)
            formdata.append('password' , password)
            formdata.append('otp' , enterotp)
            formdata.append('mesg_id' , msgid)

            setLoading(true);

            axios.post('https://drfrnd.com/api.php?type=register' , formdata)
            .then((response)=>{
                console.log(response);
                if(response.data.status === 1){
                    setLoading(false);
                    localStorage.setItem('user_id' , response.data.data.user_id);
                    localStorage.setItem('name' , response.data.data.name);
                    navigate("/");
                }else{
                    setLoading(false);
                    alert(response.data.message);
                }
            })
        }

    }

    return(
        <>
        <main className="main-wrapper">
        
        <div className="axil-shop-area axil-section-gap bg-color-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="col-lg-6 offset-lg-3">
                      <div className="">
                        
                        {
                            showotp ? (
                                <div className="axil-signin-form">
                                    <h3 className="title">Enter OTP.</h3>
                                    <form className="singin-form">
                                        <div className="form-group">
                                            <label>Enter OTP</label>
                                            <input type="text" className="form-control" name="enter_otp" value={enterotp} onChange={(e)=> setEnterotp(e.target.value)}/>
                                        </div>

                                        <button disabled={loading} type="button" onClick={()=>submitotp()} className="axil-btn btn-bg-primary submit-btn">
                                    
                                        {loading ? 'Loading...' : 'Submit'}

                                        </button>
                                        
                                    </form>
                                </div>
                            ):(
                                <div className="axil-signin-form">
                                    <h3 className="title">Sign up to DrFrnd.</h3>
                                    <p className="b2 mb--55">Enter your detail below</p>
                                    <form className="singin-form">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" className="form-control" name="first_name" value={firstname} onChange={(e)=> setFirstname(e.target.value)}/>
                                            {errors.firstname && <div className="error">{errors.firstname}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control" name="last_name" value={lastname} onChange={(e)=> setLastname(e.target.value)}/>
                                            {errors.lastname && <div className="error">{errors.lastname}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type="text" className="form-control" name="phone" value={phone} onChange={(e)=> setPhone(e.target.value)}/>
                                            {errors.phone && <div className="error">{errors.phone}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control" name="email" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                                            {errors.email && <div className="error">{errors.email}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" className="form-control" name="password" value={password} onChange={(e)=> setPassword(e.target.value)}/>
                                            {errors.password && <div className="error">{errors.password}</div>}
                                        </div>
                                        <div className="form-group d-flex align-items-center justify-content-between">
                                            <button disabled={loading} type="button" onClick={()=>submitdata()} className="axil-btn btn-bg-primary submit-btn">
                                                {loading ? 'Loading...' : 'Sign Up'}
                                            </button>
                                            <Link to="/login" className="forgot-btn">Login</Link>
                                        </div>
                                    </form>
                                </div>
                            )
                        }
                        
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
        </main>
        </>
    )
}

export default Signup;