import React ,{useEffect , useState} from 'react';
import {Link , useParams} from 'react-router-dom';
import axios from "axios";
import { useSelector } from 'react-redux';

function CategoryProduct(){

 
    const [data , SetProduct] = useState([]);
    const [pro_status , SetProstatus] = useState(0);
    const {currency,currency_name} = useSelector((state) => state.internal);

    const [catname , Setcatname] = useState('');
    const [catslug , Setcatslug] = useState('');

    const params = useParams();
    const cat_id = params.id;

    useEffect(()=>{
        load_product();

        category_info();

    },[currency,currency_name,cat_id])

    function load_product(){
        axios.post(`https://drfrnd.com/api.php?type=catproduct&cat_id=${cat_id}&cur_id=${currency}`).then((result)=>
        {
            //  console.log(result);
            SetProduct(result.data.data); 
            SetProstatus(result.data.status);
        })
    } 

    function category_info(){
        axios.post(`https://drfrnd.com/api.php?type=categoryinfo&cat_id=${cat_id}`).then((result)=>
        {
            console.log(result);
            Setcatname(result.data.data.cat_name);
            Setcatslug(result.data.data.cat_slug);
        })
    }


    return(
        <>
    <main className="main-wrapper">
        <div className="axil-breadcrumb-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8">
                        <div className="inner">
                            <ul className="axil-breadcrumb">
                                <li className="axil-breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="separator"></li>
                                <li className="axil-breadcrumb-item active" aria-current="page">{catslug}</li>
                            </ul>
                            <h1 className="title">{catname}</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4">
                        <div className="inner">
                            <div className="bradcrumb-thumb">
                                <img src="/assets/images/product/product-45.png" alt="product"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="axil-shop-area axil-section-gap bg-color-white">
            <div className="container">
            
                {pro_status === 0 && (
                    <div className="row row--15">
                        <div className="col-xl-12 col-lg-12 col-sm-12 text-center"> 
                        <h2 style={{marginTop:'40px'}}>No product found</h2>
                        </div>
                    </div>
                )}


                {pro_status === 1 && (
                <div className="row row--15">
                    {
                         data.map((item , kyes)=>
                         <div className="col-xl-3 col-lg-4 col-sm-6" key={kyes}>
                                <div className="axil-product product-style-one has-color-pick mt--40">
                                    <div className="thumbnail">
                                    <Link to={`/singleproduct/${item.pro_id}`}>
                                        <img src={item.pro_img} alt={item.pro_name} />
                                    </Link>
                                        <div className="label-block label-right">
                                            <div className="product-badget">{item.pro_discount}% OFF</div>
                                        </div>
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                            <h5 className="title">
                                               <Link to={`/singleproduct/${item.pro_id}`} >{item.pro_name}</Link>
                                            </h5>
                                            <div className="product-price-variant">
                                                <span className="price current-price">{item.pro_currency} {item.pro_price}</span>
                                                <span className="price old-price">{item.pro_currency} {item.pro_main_price}</span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                )}
                
                
            </div>
        </div>
    </main>
        </>
    )
}

export default CategoryProduct;