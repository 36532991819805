import React, { useEffect } from "react";
import Bottomservices from "./Bottomservices";
import { useSelector , useDispatch } from 'react-redux';
import { fetchComments } from "../Store/commenSlice";
import { Link } from "react-router-dom";

function Footer(){
    const dispatch = useDispatch();
    const commenData = useSelector(state => state.commen);

    useEffect(()=>{
        dispatch(fetchComments())
    },[dispatch]) 

    if (commenData.data === null) {
        return (
          <>
            <h1>Loading.........</h1>
          </>
        );
    }

    const today = new Date();
    const year = today.getFullYear();

    return(
    <>
<Bottomservices/>
    <footer className="axil-footer-area footer-style-2" style={{'background':'#efefef94', paddingTop:'30px'}}>
             <div className="container">
                <div className="row">
                  <div className="col-lg-5 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">How to Reach Us</h5>
                             <div className="inner">
                                <p style={{marginBottom:'10px', color:'#000'}}>{commenData.data.address}</p>                            
                                <ul className="support-list-item">
                                <li>
                                    <a href={`mailto:${commenData.data.email}`}>
                                        <i className="fal fa-envelope-open"></i> {commenData.data.email}
                                    </a>
                                </li>
                                <li>
                                    <a href={`tel:${commenData.data.phone}`}>
                                        <i className="fal fa-phone-alt"></i> {commenData.data.phone}
                                    </a>
                                </li>
                                </ul>
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">Important link</h5>
                            <div className="inner">
                                <ul>
                                    <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/return-policy">Return Policy</Link></li>
                                    <li><Link to="/shipping-policy">Delivery & Shipping Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">Category</h5>
                            <div className="inner">
                                <ul>
                                {
                                commenData.data.category.map((items , kyes)=>
                                    <li>
                                        <Link to={`/singlecategory/${items.id}`}>{items.name}</Link>
                                    </li>
                                )
                                }
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
             </div>

        <div className="copyright-area copyright-default separator-top">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-3">
                        <div className="social-share">
                            <a href={commenData.data.fblink}><i className="fab fa-facebook-f"></i></a>
                            <a href={commenData.data.instalink}><i className="fab fa-instagram"></i></a>
                            
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12">
                        <div className="copyright-left d-flex flex-wrap justify-content-center">
                            <ul className="quick-link">
                                <li>© {year} DrFrnd. All Right Reserved. Developed By <a target="_blank" href={commenData.data.weblink}><b>{commenData.data.webname}</b></a>.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-12">
                        <div className="copyright-right d-flex flex-wrap justify-content-xl-end justify-content-center align-items-center">
                            <span className="card-text">Accept For</span>
                            <ul className="payment-icons-bottom quick-link">
                                <li><img src="../assets/images/PayPal.png" alt="PayPal"/></li>
                                <li><img src="../assets/images/Razorpay_logo.svg" alt="Razorpay"/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
             </footer>
        </>
    )
}


export default Footer;