import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

function Singleorder(){

    const params = useParams();
    const order_id = params.id;
    const txnid = params.txnid;

    const [singleproduct, setSingleProduct] = useState([]);
    const [otherdata, setOther] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getorder();
    }, [order_id,txnid]);


    async function getorder() {
        try {
            const result = await axios.get(`https://drfrnd.com/api.php?type=singleorder&order_id=${order_id}&txn_id=${txnid}`);
            if(result.data.status){
                setSingleProduct(result.data.product);
                setOther(result.data.otherdata);
            }
        } catch (error) {
            console.error(error);
            //setError('Failed to fetch product');
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <div>Loading...</div>;

    return(
        <>
         <main className="main-wrapper">
            <div className="axil-breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="inner">
                                <ul className="axil-breadcrumb">
                                    <li className="axil-breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="separator"></li>
                                    <li className="axil-breadcrumb-item active" aria-current="page">Single Order</li>
                                </ul>
                                <h1 className="title">Single Order</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <div className="inner">
                                <div className="bradcrumb-thumb">
                                    <img src="/assets/images/product/product-45.png" alt="product"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="axil-shop-area axil-section-gap bg-color-white">
                <div className="container">
                        <div className="row row--15">
                            <div className="col-xl-6 col-lg-6 col-sm-12"> 
                                <h2><b>Order ID</b> : {otherdata.order_id}</h2>
                                <p style={{marginBottom:"10px"}}><b>Order Date </b>: {otherdata.order_date}</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-sm-12" style={{textAlign:'right'}}> 
                                <p style={{marginBottom:"0px"}}><b>Status</b> : {otherdata.status}</p>
                                <p style={{marginBottom:"0px"}}><b>Total</b> : {otherdata.total_amount} {otherdata.currency_name}</p>
                                <p style={{marginBottom:"0px"}}><b>Tracking Number</b> : {otherdata.tracking_number}</p>
                                <p style={{marginBottom:"0px"}}><b>Txn id</b> : {otherdata.txn_id}</p>
                            </div>

                            <hr></hr>
                        </div>

                        { singleproduct && singleproduct.length > 0 && (
                            singleproduct.map((item, key) => (
                                <div className="row mb-5" key={key}>
                                    <div className="col-xl-1 col-lg-1 col-sm-12">
                                        <img style={{ height: "80px" }} src={item.pro_img} alt={item.pro_name} />
                                    </div> 
                                    <div className="col-xl-8 col-lg-8 col-sm-12">
                                        <p style={{ marginBottom: '0px' }}>{item.pro_name}</p>
                                        <p>{item.pro_quity_selects}</p>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-12">
                                        <p style={{ marginBottom: '0px' }}>{otherdata.currency_name} {item.pro_prices}</p>
                                        <p>Qty: {item.pro_qty}</p>
                                    </div>
                                </div>
                            ))
                        )}
                        
                    <hr></hr>
                    <div className="row row--15">
                            <div className="col-xl-12 col-lg-12 col-sm-12"> 
                                <div><b>Address:</b></div>
                                <div>{otherdata.billing_address} {otherdata.billing_address2}</div>
                                <div>{otherdata.city} {otherdata.pincode} {otherdata.country}</div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-sm-12"> 
                                <div><b>Remark:</b></div>
                                <div>{otherdata.remark}</div>
                            </div>
                    </div>
                </div>
            </div>
         </main>
        </>
    )
}

export default Singleorder;