import React, { useState , useEffect } from 'react';
import {useNavigate , Link } from 'react-router-dom';
import axios from "axios";

function Profile(){

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('nav-orders');
    const [orders, setOrders] = useState([]);
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    const uuser_id = localStorage.getItem('user_id');

    useEffect(() => {
      GetOrder();

      if(localStorage.getItem('user_id') === null){
        navigate("/login");
      }
    }, [uuser_id]);

    function GetOrder(){
          var user_id = localStorage.getItem('user_id');
          const formdata = new FormData() 
          formdata.append('user_id' , user_id)
          axios.post('https://drfrnd.com/api.php?type=getorder' , formdata)
          .then((response)=>{
            if(response.data.status === 1){
              setOrders(response.data.data);
            }else{
              console.log(response.data.message);
            }

          })
    }

    function handleLogout() {
      // Clear user data from localStorage
      localStorage.removeItem('user_id');
      localStorage.removeItem('name');
      navigate("/login");
    };


  return(
    <>

    <div className="axil-breadcrumb-area">
      <div className="container">
          <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                  <div className="inner">
                      <ul className="axil-breadcrumb">
                          <li className="axil-breadcrumb-item"><Link to="/">Home</Link></li>
                          <li className="separator"></li>
                          <li className="axil-breadcrumb-item active" aria-current="page">Dashboard</li>
                      </ul>
                      <h1 className="title">Dashboard</h1>
                  </div>
              </div>
              <div className="col-lg-6 col-md-4">
                  <div className="inner">
                      <div className="bradcrumb-thumb">
                          <img src="/assets/images/product/product-45.png" alt="product"/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="axil-dashboard-area axil-section-gap">
      <div className="container">
        <div className="axil-dashboard-warp">
          <div className="axil-dashboard-author">
            <div className="media">
              <div className="thumbnail">
                <img src="./assets/images/product/author1.png" alt="Hello Annie" />
              </div>
              <div className="media-body">
                <h5 className="title mb-0">{localStorage.getItem('name')}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <aside className="axil-dashboard-aside">
                <nav className="axil-dashboard-nav">
                  <div className="nav nav-tabs" role="tablist">
                    
                    <Link className={`nav-item nav-link ${activeTab === 'nav-orders' ? 'active' : ''}`}
                      onClick={() => handleTabClick('nav-orders')}
                      role="tab"> <i className="fas fa-shopping-basket"></i>Orders </Link>
                    
                    
                    {/* <a
                      className={`nav-item nav-link ${activeTab === 'nav-account' ? 'active' : ''}`}
                      onClick={() => handleTabClick('nav-account')}
                      role="tab"
                    >
                      <i className="fas fa-user"></i>Account Details
                    </a> */}
                    <Link className="nav-item nav-link" onClick={handleLogout} role="tab"> <i className="fal fa-sign-out"></i>Logout </Link>
                  </div>
                </nav>
              </aside>
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="tab-content">
                <div className={`tab-pane fade ${activeTab === 'nav-orders' ? 'show active' : ''}`} id="nav-orders" role="tabpanel">
                  <div className="axil-dashboard-order">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Order</th>
                            <th scope="col">Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Total</th>
                            <th scope="col">⁠Tracking Number</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            orders.length > 0 ? (
                              orders.map((item, key) => (
                                <tr key={key}>
                                  <th scope="row">#{item.order_id}</th>
                                  <td>{item.order_date}</td>
                                  <td>{item.status}</td>
                                  <td>{item.total}</td>
                                  <td>{item.tracking_number}</td>
                                  <td>
                                    <Link to={`/singleorder/${item.order_id}/${item.txn_id}`} className="axil-btn view-btn">
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  No orders found.
                                </td>
                              </tr>
                            )
                          }
                          
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'nav-account' ? 'show active' : ''}`} id="nav-account" role="tabpanel">
                  <div className="col-lg-9">
                    <div className="axil-dashboard-account">
                      <form className="account-details-form">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input type="text" className="form-control" defaultValue="Annie" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input type="text" className="form-control" defaultValue="Mario" />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group mb--40">
                              <label>Country/ Region</label>
                              <select className="select2">
                                <option value="1">United Kindom (UK)</option>
                                <option value="2">United States (USA)</option>
                                <option value="3">United Arab Emirates (UAE)</option>
                                <option value="4">Australia</option>
                              </select>
                              <p className="b3 mt--10">This will be how your name will be displayed in the account section and in reviews</p>
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="title">Password Change</h5>
                            <div className="form-group">
                              <label>Password</label>
                              <input type="password" className="form-control" defaultValue="123456789101112131415" />
                            </div>
                            <div className="form-group">
                              <label>New Password</label>
                              <input type="password" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label>Confirm New Password</label>
                              <input type="password" className="form-control" />
                            </div>
                            <div className="form-group mb--0">
                              <input type="submit" className="axil-btn" value="Save Changes" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </>
  )
}


export default Profile;
