import { createSlice } from '@reduxjs/toolkit';

const internalSlice = createSlice({
    name: 'internal',
    initialState: {
        currency: 22,
        currency_name: "INR",
    },
    reducers: {
        addOrUpdateData: (state, action) => {
            state.currency = action.payload.id;
            state.currency_name = action.payload.cur_name;
        },
    },
});

export const { addOrUpdateData } = internalSlice.actions;
export default internalSlice.reducer;
