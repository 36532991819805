import React , {useEffect , useState} from "react";
import {Link , useNavigate} from 'react-router-dom';
import 'jquery-ui-dist/jquery-ui';
import { useSelector , useDispatch } from 'react-redux';
import { remove , drecreaseQuantity , increaseQuantity , resetCart } from '../Store/cartSlice';

function Cart(){

    const navigate = useNavigate();
    const cartProduct = useSelector(state => state.cart);
    const {currency,currency_name} = useSelector((state) => state.internal);

    const [currentcur, setCurrentcur] = useState('');

    const dispatch = useDispatch();
    useEffect(()=>{
        if(currentcur !== currency && currentcur !== ''){
            dispatch(resetCart());
            navigate('/');
        }else{
            setCurrentcur(currency);
        }
    },[currency , currency_name]) 

    const removeToCart =(productid)=>{
        dispatch(remove(productid));
    }

    return(
        <>
<main className="main-wrapper">
    <div className="axil-breadcrumb-area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-8">
                    <div className="inner">
                        <ul className="axil-breadcrumb">
                            <li className="axil-breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="separator"></li>
                            <li className="axil-breadcrumb-item active" aria-current="page">Cart</li>
                        </ul>
                        <h1 className="title">Cart</h1>
                    </div>
                </div>
                <div className="col-lg-6 col-md-4">
                    <div className="inner">
                        <div className="bradcrumb-thumb">
                            <img src="/assets/images/product/product-45.png" alt="product"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div className="axil-product-cart-area axil-section-gap">
    <div className="container">
        <div className="axil-product-cart-wrap">
            <div className="product-table-heading">
                <h4 className="title">Your Cart</h4>
            </div>
            <div className="table-responsive">
                <table className="table axil-product-table axil-cart-table mb--40">
                    <thead>
                        <tr>
                            <th scope="col" className="product-remove"></th>
                            <th scope="col" className="product-thumbnail">Product</th>
                            <th scope="col" className="product-title"></th>
                            <th scope="col" className="product-price">Price</th>
                            <th scope="col" className="product-quantity">Quantity</th>
                            <th scope="col" className="product-subtotal">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cartProduct.length > 0 && (
                                cartProduct.map((item , kyes)=>
                                        <tr key={kyes}>
                                            <td className="product-remove"><Link onClick={() => removeToCart(item.pro_id)} className="remove-wishlist"><i className="fal fa-times"></i></Link></td>
                                            <td className="product-thumbnail"><Link to={`/singleproduct/${item.pro_id}`} ><img src={item.pro_img} alt={item.pro_name}/></Link></td>
                                            <td className="product-title"><Link to={`/singleproduct/${item.pro_id}`} >{item.pro_name} </Link> <i className="fal fa-times"></i> {item.pro_qty}</td>
                                            <td className="product-price" data-title="Price"><span className="currency-symbol">{item.pro_currency}</span> {item.pro_price}</td>
                                            <td className="product-quantity" data-title="Qty">
                                                <div className="pro-qty">
                                                <span className="dec qtybtn" onClick={() => dispatch(drecreaseQuantity({ _id: item.pro_id  }))} >-</span>
                                                    <input type="number" className="quantity-input" value={item.pro_qty} />
                                                    <span className="inc qtybtn" onClick={() => dispatch(increaseQuantity({ _id: item.pro_id }))} >+</span>
                                                </div>
                                            </td>
                                            <td className="product-subtotal" data-title="Subtotal"><span className="currency-symbol">{item.pro_currency}</span> {item.pro_qty * item.pro_price}</td>
                                            </tr>
                                    )
                                
                                
                            )
                        }
                                                
                    </tbody>
                </table>
            </div>
            {/* <div className="cart-update-btn-area">
                <div className="input-group product-cupon">
                    <input placeholder="Enter coupon code" type="text" />
                    <div className="product-cupon-btn">
                        <button type="submit" className="axil-btn btn-outline">Apply</button>
                    </div>
                </div>
                <div className="update-btn">
                    <a href="" className="axil-btn btn-outline">Update Cart</a>
                </div>
            </div> */}
            {
                cartProduct.length > 0 && (
                     <Link to="/checkout" className="axil-btn btn-bg-primary checkout-btn">Process to Checkout</Link>
                )
            }
            
            
        </div>
    </div>
</div>
</main>
        </>
    )
}


export default Cart;