import React ,{useEffect , useState} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";
import { useSelector} from 'react-redux';

function Homeproduct(){

    const [data , SetProduct] = useState([]);
    const [pro_status , SetProstatus] = useState(0);

    const {currency,currency_name} = useSelector((state) => state.internal);

    useEffect(()=>{
        load_product();
    },[currency,currency_name])

    function load_product(){
        axios.post(`https://drfrnd.com/api.php?type=homeproduct&cur_id=${currency}`).then((result)=>
        {
            //  console.log(result);
            SetProduct(result.data.data); 
            SetProstatus(result.data.status);
        })
    }

    return(
        <>
        <div className="axil-product-area bg-color-white axil-section-gap">
            <div className="container">
                <div className="section-title-wrapper">
                    <span className="title-highlighter highlighter-primary"> <i className="far fa-shopping-basket"></i> Our Products</span>
                    <h2 className="title">Our Recent Added Products</h2>
                </div>
                <div className="explore-product-activation slick-layout-wrapper slick-layout-wrapper--15 axil-slick-arrow arrow-top-slide">
                    <div className="slick-single-layout">
                
                {pro_status === 1 && (
                <div className="row row--15">
                    {
                         data.map((item , kyes)=>
                         <div className="col-xl-3 col-lg-4 col-sm-6" key={kyes}>
                                <div className="axil-product product-style-one has-color-pick mt--40">
                                    <div className="thumbnail">
                                    <Link to={`/singleproduct/${item.pro_id}`}>
                                        <img src={item.pro_img} alt={item.pro_name} />
                                    </Link>
                                        
                                        <div className="label-block label-right">
                                            <div className="product-badget">{item.pro_discount}% OFF</div>
                                        </div>
                                        
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                            <h5 className="title">
                                                <Link to={`/singleproduct/${item.pro_id}`} >{item.pro_name}</Link>
                                                </h5>
                                            <div className="product-price-variant">
                                                <span className="price current-price">{item.pro_currency} {item.pro_price}</span>
                                                <span className="price old-price">{item.pro_currency} {item.pro_main_price}</span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                )}
                    </div>
                </div>
        </div>
        </div>
        </>
    )
}

export default Homeproduct;