import React from "react";

function Bottomservices(){
    return(
        <>
         <div className="service-area">
        <div className="container">
            <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="/assets/images/icons/service1.png" alt="Service"/>
                        </div>
                        <div className="content">
                            <h6 className="title" style={{marginTop:'15px'}}>Fast &amp; Secure Delivery</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="/assets/images/icons/service2.png" alt="Service" />
                        </div>
                        <div className="content">
                            <h6 className="title" style={{marginTop:'15px'}}>Money Back Guarantee</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="/assets/images/icons/service3.png" alt="Service"/>
                        </div>
                        <div className="content">
                            <h6 className="title" style={{marginTop:'15px'}}>7 Days Return Policy</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="service-box service-style-2">
                        <div className="icon">
                            <img src="/assets/images/icons/service4.png" alt="Service"/>
                        </div>
                        <div className="content">
                            <h6 className="title" style={{marginTop:'15px'}}>Pro Quality Support</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}

export default Bottomservices;