import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import commenSlice from "./commenSlice";
import internalSlice from "./internalSlice";
const store = configureStore({
    reducer : {
        cart : cartSlice,
        commen : commenSlice,
        internal : internalSlice,
    }
})

export default store;