import React from "react";

function Serve(){
    return(
        <>
         <div className="service-area">
            <div className="container">
                <div className="row row-cols-xl-5 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1 row--20">
                    <div className="col">
                        <div className="service-box">
                            <div className="icon">
                                <img src="assets/images/icons/service1.png" alt="Service" />
                            </div>
                            <h6 className="title">Fast &amp; Secure Delivery</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="service-box">
                            <div className="icon">
                                <img src="assets/images/icons/service2.png" alt="Service" />
                            </div>
                            <h6 className="title">100% Guarantee On Product</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="service-box">
                            <div className="icon">
                                <img src="assets/images/icons/service3.png" alt="Service" />
                            </div>
                            <h6 className="title">24 Hour Return Policy</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="service-box">
                            <div className="icon">
                                <img src="assets/images/icons/service4.png" alt="Service" />
                            </div>
                            <h6 className="title">24 Hour Return Policy</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="service-box">
                            <div className="icon">
                                <img src="assets/images/icons/service5.png" alt="Service" />
                            </div>
                            <h6 className="title">Next Level Pro Quality</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Serve;