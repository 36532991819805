import React from "react";
import { Link } from "react-router-dom";

function Shippingpolicy(){
   return(
    <>
    <main class="main-wrapper">
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item">Home</li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">Shipping & Delivery Policies</li>
                            </ul>
                            <h1 class="title">Shipping & Delivery Policies</h1>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4">
                        <div class="inner">
                            <div class="bradcrumb-thumb">
                                <img src={`/assets/images/product/product-45.png`} alt="nav" / >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="axil-about-area about-style-1 axil-section-gap ">
            <div class="container">
            <div class="row">
                <div class="col-md-12">
                        <p>This shipping policy explains how drfrnd operates its shipping procedures and how we strive to meet your expectations with every order. Whether you’re a first-time buyer or a returning customer, we want to ensure that your experience with us is smooth and satisfactory, right from placing your order to the moment it arrives at your doorstep. </p>
                        <p>Please read this shipping policy together with our <Link to="/terms-conditions">terms and conditions</Link> to familiarize yourself with the rest of our general guidelines.</p>
                        <div class="toc"><h3>Table of contents</h3><ol class="toc"><li><a href="#shipping-and-delivery-options">Shipping and Delivery Options</a></li><li><a href="#delayed-orders">Delayed Orders</a></li><li><a href="#returns-and-exchanges">Returns and Exchanges</a></li><li><a href="#contact-information">Contact Information</a></li></ol></div>
                        <h2 id="shipping-and-delivery-options">Shipping and Delivery Options</h2>
                        <p>We offer a variety of shipping options to suit the needs of our customers.</p>
                        <h3>Free Shipping</h3>
                        <p>As part of our commitment to an exceptional shopping experience, we are pleased to offer free shipping for Must Be Member.</p>
                        <h3>Shipping Methods</h3>
                        <p>We offer a variety of shipping options to suit the needs of our customers:</p>
                        <ul><li>COD: Max 7 Days</li></ul>
                        <p>We strive for a swift preparation process and orders are typically processed and dispatched within Max 3 Days so that customers can receive their items promptly.</p>
                        <h2 id="delayed-orders">Delayed Orders</h2>
                        <p>Unexpected delays can occur due to various reasons such as logistic challenges, inclement weather, high demand, or carrier issues. We are committed to handling these situations with transparency and efficiency. In the event of a delay, our priority is to keep you informed. We will promptly notify you with updates on the status of your order and the expected new delivery time. Our goal is to provide clear and accurate information so you can plan accordingly.</p>
                        <h2 id="returns-and-exchanges">Returns and Exchanges</h2>
                        <p>If you have any questions about refunds, returns or exchanges, please review our <Link to="/return-policy">refund policy</Link>.</p>
                        <h2 id="contact-information">Contact Information</h2>
                        <p>If you have any questions or concerns regarding our shipping policy, we encourage you to contact us using the details below:</p>
                        <ul><li><Link to="contact">https://drfrnd.com/contact</Link></li><li><a href="mailto:contact@drfrnd.com">contact@drfrnd.com</a></li><li>Pratap Street Chuna mandi Paharganj.</li></ul>
                        <p>This document was last updated on August, 2024</p>

                    </div>
                </div>
            </div>
        </div>
        
    </main>
    </>
   )
}

export default Shippingpolicy;