import React from "react";
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Category(){

    const commenData = useSelector(state => state.commen);
    if (commenData.data === null) {
        return (
          <>
            <h1>Loading.........</h1>
          </>
        );
      }
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // for tablets and desktops
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768, // for mobile devices
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480, // for smaller mobile devices
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    

    return(
        <>
        <div className="service-area">
            <div className="container">
                <div className="section-title-wrapper">
                    <span className="title-highlighter highlighter-primary"> <i className="far fa-shopping-basket"></i> Our Category</span>
                    <h2 className="title">Explore our Category</h2>
                </div>
                <div className="row row--20">
                <SlickSlider {...settings}>

                    {
                    commenData.data.all_category.map((items , kyes)=>
                        <div className="col">
                        <div className="service-box" style={{padding:'10px', marginRight:'10px'}}>
                            <Link to={`/singlecategory/${items.id}`}>
                            <img src={items.url} alt="Service" />
                            <h6 className="title">{items.name}</h6>
                            </Link>
                        </div>
                        </div>
                    )
                    }
                    
                </SlickSlider>
        </div>
        </div>
        </div>
        </>
    )
}


export default Category;