import './App.css';
import 'jquery-ui-dist/jquery-ui';
import {BrowserRouter ,Route , Routes } from 'react-router-dom';
import Home from './Component/Home';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import Login from './Component/Login';
import Shop from './Component/Shop';
import Signup from './Component/Signup';
import Singleproduct from './Component/Singleproduct';
import About from './Component/About';
import Cart from './Component/Cart';
import Checkout from './Component/Checkout';
import Contact from './Component/Contact';
import Profile from './Component/Profile';
import Thankyou from './Component/Thankyou';
import Test from './Component/Test';
import TermsConditions from './Component/TermsConditions';
import Privacypolicy from './Component/⁠Privacypolicy';
import Returnpolicy from './Component/Returnpolicy';
import Shippingpolicy from './Component/Shippingpolicy';
import CategoryProduct from './Component/CategoryProduct';
import Singleorder from './Component/Singleorder';
function App() {

  return (
    <>
      <BrowserRouter>
       <Navbar/>
        <Routes>
          
          <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/singleproduct/:id" element={<Singleproduct/>}/>
          <Route path="/shop" element={<Shop/>}/>
          <Route path="/cart" element={<Cart/>}/>
          <Route path="/checkout" element={<Checkout/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/thankyou/:id" element={<Thankyou/>}/>
          <Route path="/test" element={<Test/>}/>
          <Route path="/terms-conditions" element={<TermsConditions/>}/>
          <Route path="/privacy-policy" element={<Privacypolicy/>}/>
          <Route path="/return-policy" element={<Returnpolicy/>}/>
          <Route path="/shipping-policy" element={<Shippingpolicy/>}/>
          <Route path="/singlecategory/:id" element={<CategoryProduct/>}/>
          <Route path="/singleorder/:id/:txnid" element={<Singleorder/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
