import React, { useEffect , useState } from "react";
import { useSelector , useDispatch } from 'react-redux';
import { fetchComments } from "../Store/commenSlice";
import axios from "axios";

function Contact(){

    const dispatch = useDispatch();
    const commenData = useSelector(state => state.commen);
    const [formData, setFormData] = useState({
        contactname: '',
        contactphone: '',
        contactemail: '',
        contactmessage:''
    });
    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    useEffect(()=>{
        dispatch(fetchComments())
    },[dispatch]) 

    if (commenData.data === null) {
        return (
            <>
            <h1>Loading.........</h1>
            </>
        );
    }

    const validate = () => {
    let tempErrors = {};
    let isValid = true;

    // Name validation
    if (!formData.contactname) {
      tempErrors.contactname = 'Name is required';
      isValid = false;
    } else if (formData.contactname.length < 3) {
      tempErrors.contactname = 'Name must be at least 3 characters long';
      isValid = false;
    }

    // Email validation
    const emailPattern = /\S+@\S+\.\S+/;
    if (!formData.contactemail) {
      tempErrors.contactemail = 'Email is required';
      isValid = false;
    } else if (!emailPattern.test(formData.contactemail)) {
      tempErrors.contactemail = 'Invalid email format';
      isValid = false;
    }

    // Password validation
    if (!formData.contactphone) {
      tempErrors.contactphone = 'Password is required';
      isValid = false;
    } else if (formData.contactphone.length < 10) {
      tempErrors.contactphone = 'Phone number must be at least 10 digit';
      isValid = false;
    }

    if (!formData.contactmessage) {
        tempErrors.contactmessage = 'Message is required';
        isValid = false;
      } else if (formData.contactmessage.length < 10) {
        tempErrors.contactmessage = 'Message atleast 10 character';
        isValid = false;
      }
    setErrors(tempErrors);
    return isValid;
  };


    const handleSubmit = (e) => {
        if (validate()) {
            const checkdata = new FormData();
            checkdata.append('contactname', formData.contactname);
            checkdata.append('contactemail', formData.contactemail);
            checkdata.append('contactphone', formData.contactphone);
            checkdata.append('contactmessage', formData.contactmessage);

            axios.post('https://drfrnd.com/api.php?type=contactus' , checkdata)
                .then((response)=>{
                alert(response.data.message);
                setFormData({
                    contactname: '',
                    contactphone: '',
                    contactemail: '',
                    contactmessage:''
                });
            })

        }
    };



        
  return(
    <>
     <main className="main-wrapper">
            {/* Start Breadcrumb Area */}
            <div className="axil-breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="inner">
                                <ul className="axil-breadcrumb">
                                    <li className="axil-breadcrumb-item"><a href="index.html">Home</a></li>
                                    <li className="separator"></li>
                                    <li className="axil-breadcrumb-item active" aria-current="page">Contact</li>
                                </ul>
                                <h1 className="title">Contact With Us</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <div className="inner">
                                <div className="bradcrumb-thumb">
                                    <img src="assets/images/product/product-45.png" alt="nav" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrumb Area */}

            {/* Start Contact Area */}
            <div className="axil-contact-page-area axil-section-gap">
                <div className="container">
                    <div className="axil-contact-page">
                        <div className="row row--30">
                            <div className="col-lg-8">
                                <div className="contact-form">
                                    <h3 className="title mb--10">We would love to hear from you.</h3>
                                    <p>If you’ve got great products your making or looking to work with us then drop us a line.</p>
                                    <form id="contact-form" method="POST" className="axil-contact-form">
                                        <div className="row row--10">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="contact-name">Name <span>*</span></label>
                                                    <input type="text" name="contactname" id="contact-name" value={formData.contactname} onChange={handleInputChange} />
                                                    {errors.contactname && <span className="error">{errors.contactname}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="contact-phone">Phone <span>*</span></label>
                                                    <input type="text" name="contactphone" value={formData.contactphone} onChange={handleInputChange} id="contact-phone" />
                                                    {errors.contactphone && <span className="error">{errors.contactphone}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="contact-email">E-mail <span>*</span></label>
                                                    <input type="email" name="contactemail" value={formData.contactemail} onChange={handleInputChange} id="contact-email" />
                                                    {errors.contactemail && <span className="error">{errors.contactemail}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="contact-message">Your Message</label>
                                                    <textarea name="contactmessage" id="contact-message" onChange={handleInputChange} cols="1" rows="2">{formData.contactmessage}</textarea>
                                                    {errors.contactmessage && <span className="error">{errors.contactmessage}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mb--0">
                                                    <button name="submit" onClick={handleSubmit} type="button" id="submit" className="axil-btn btn-bg-primary">Send Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-location mb--40">
                                    <h4 className="title mb--20">Our Store</h4>
                                    <span className="address mb--20">{commenData.data.address}</span>
                                    <span className="phone">Phone: {commenData.data.phone}</span>
                                    <span className="email">Email: {commenData.data.email}</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    {/* Start Google Map Area */}
                    <div className="axil-google-map-wrap axil-section-gap pb--0">
                        <div className="mapouter">
                            <div className="gmap_canvas">
                            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.577989473105!2d77.20694887544283!3d28.64240758363222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd43a4574c9d%3A0xe03134aa555bc58!2s1765%2C%20Pratap%20St%2C%20Chuna%20Mandi%2C%20Paharganj%2C%20New%20Delhi%2C%20Delhi%20110055!5e0!3m2!1sen!2sin!4v1723694538685!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    {/* End Google Map Area */}
                </div>
            </div>
            {/* End Contact Area */}
        </main>
    </>
  )
}

export default Contact;
