import React from "react";

function About(){
    return(
        <>
        <main class="main-wrapper">
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><a href="index.html">Home</a></li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">About Us</li>
                            </ul>
                            <h1 class="title">About Our Store</h1>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4">
                        <div class="inner">
                            <div class="bradcrumb-thumb">
                                <img src={`/assets/images/product/product-45.png`} alt="Image" / >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="axil-about-area about-style-1 axil-section-gap ">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-4 col-lg-6">
                        <div class="about-thumbnail">
                            <div class="thumbnail">
                                <img src="assets/images/about/about-01.png" alt="About Us" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-6">
                        <div class="about-content content-right">
                            
                            <div class="row">
                                <div class="col-xl-12">
                                   <h3 class="title">Welcome to Drfrnd</h3>
                                   <p> At Drfrnd, we believe in the power of quality, affordability, and excellent customer service. Founded in [Year of Establishment], our mission has always been to provide our customers with an unparalleled shopping experience by offering a wide range of products that meet their diverse needs.</p>
                                </div>

                                <div class="col-xl-12">
                                   <h3 class="title">Our Story</h3>
                                   <p> What started as a small idea has grown into a thriving online store serving customers all over India. Our founders, Drfrnd, envisioned a place where people could find fashionable products that are not only high in quality but also fairly priced. Through hard work, dedication, and a passion for excellence, Drfrnd has become a go-to destination for fashionable products.</p>
                                </div>
                                
                                <div class="col-xl-12">
                                   <h3 class="title">Why Choose Us?</h3>
                                   <ul>
                                    <li><strong>Quality Products: </strong> We never compromise on quality. Every item in our store meets strict quality standards to ensure that you get the best value for your money.</li>
                                    <li><strong>Affordable Prices: </strong>We believe that quality shouldn’t come with a hefty price tag. That’s why we offer competitive pricing on all our products.</li>
                                    <li><strong>Customer Satisfaction: </strong>Your satisfaction is our top priority. Our customer service team is always ready to assist you with any questions or concerns you may have.</li>
                                    <li><strong>Fast & Reliable Shipping: </strong> We understand that timely delivery is important to you. That’s why we work hard to ensure your orders arrive quickly and safely.</li>
                                   </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    
    
        <div class="axil-newsletter-area axil-section-gap" style={{paddingTop:'0px'}}>
            <div class="container">
                <div class="etrade-newsletter-wrapper bg_image bg_image--5">
                    <div class="newsletter-content">
                        <span class="title-highlighter highlighter-primary2"><i class="fas fa-envelope-open"></i>Newsletter</span>
                        <h2 class="title mb--40 mb_sm--30">Get weekly update</h2>
                        <div class="input-group newsletter-form">
                            <div class="position-relative newsletter-inner mb--15">
                                <input placeholder="example@gmail.com" type="text" />
                            </div>
                            <button type="submit" class="axil-btn mb--15">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
        </>
    )
}

export default About;